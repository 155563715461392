import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { editProfileRoute, setRoleRoute } from "../../utils/APIRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import User from "../../components/User";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";
import zensorLogo from "../../assets/zensor.png";
import "./index.scss";

export default function Profile() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      if (!storedUser) {
        navigate("/login");
      } else {
        setUser(storedUser);
        setSurname(storedUser.surname);
        setName(storedUser.name);
        setEmail(storedUser.email);
        setRole(storedUser.role);
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleRoleChange = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${setRoleRoute}/${user._id}`,
        {
          role,
        },
        {
          headers: {
            accessToken: token,
          },
        }
      );
      if (response.data.status) {
        const updatedUser = { ...user, role: response.data.role };
        setUser(updatedUser);
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(updatedUser)
        );
        toast.success(response.data.msg);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("An error occurred while updating the role.");
    }
  };

  const handleProfileUpdate = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.put(
        `${editProfileRoute}/${user._id}`,
        {
          surname,
          name,
          email,
          password: currentPassword,
          newPassword,
        },
        {
          headers: {
            accessToken: token,
          },
        }
      );

      if (response.data.status) {
        const updatedUser = response.data.user;
        setUser(updatedUser);
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(updatedUser)
        );
        toast.success(response.data.msg);

        setSurname("");
        setName("");
        setEmail("");
        setCurrentPassword("");
        setNewPassword("");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("An error occurred while updating the profile.");
    }
  };

  const handleAvatarUpdate = (updatedUser) => {
    setUser(updatedUser);
    toast.success("Avatar mis à jour !");
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="profile-container">
        <div className="profile-container-update">
          <div className="profile-container-information">
            <div className="profile-container-space">
              <h2>Mon profil</h2>
              <p>Gérez les paramètres de votre profil</p>
            </div>
            <div>
              <div className="profile-primary-information">
                <div className="profile-container-space">
                  <h4>Votre photo de profil</h4>
                </div>

                <div className="profile-primary">
                  <User
                    avatar={user.avatarImage}
                    showName={false}
                    editable={true}
                    type="large"
                  />
                  <div className="profile-container-row">
                    <Select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="user">User</option>
                      <option value="mentor">Mentor</option>
                    </Select>
                    <Button onClick={handleRoleChange}>Role</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Input
            type="text"
            placeholder="Prénom"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Nom"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="email"
            placeholder="New Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <Input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <div className="profile-container-row">
            <Button onClick={handleProfileUpdate}>Update Profile</Button>
          </div>
        </div>
        <div className="profile-zensor-information">
          <div className="zensor-info-text">
            <h4>Devenez un modèle pour les autres !</h4>
            <p>
              Complétez votre profil pour montrer votre expertise et aider les
              autres à grandir.
            </p>
          </div>
          <div className="zensor-info-image">
            <img src={zensorLogo} alt="Relief" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
