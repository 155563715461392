import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserOffersRoute,
  updateUserSkillsRoute,
  removeUserSkillRoute,
  editProfileRoute,
} from "../../utils/APIRoutes";
import axios from "axios";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/Modal";
import { FiEdit2 } from "react-icons/fi";
import { IoIosRemoveCircleOutline, IoIosRemoveCircle } from "react-icons/io";
import Icon from "../../components/Icon";
import "./index.scss";
import User from "../../components/User";

export default function Account() {
  const [user, setUser] = useState(null);
  const [userOffers, setUserOffers] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [hoveredSkillIndex, setHoveredSkillIndex] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    );

    if (!storedUser) {
      navigate("/login");
    } else {
      setUser(storedUser);
      setDescription(storedUser.description);
      setCity(storedUser.localisation.city);
      setCountry(storedUser.localisation.country);
      fetchUserOffers(storedUser._id);
    }
  }, [navigate]);

  const fetchUserOffers = async (userId) => {
    try {
      const response = await axios.get(`${getUserOffersRoute}/${userId}`);
      setUserOffers(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des offres de l'utilisateur :",
        error
      );
    }
  };

  const handleEditProfileClick = () => {
    navigate("/editProfile");
  };

  const handleCreateOfferClick = () => {
    navigate("/create");
  };

  const handleAddSkill = async (userId) => {
    try {
      const response = await axios.put(`${updateUserSkillsRoute}/${userId}`, {
        newSkill,
      });

      setUser((prevUser) => ({
        ...prevUser,
        skills: [
          ...prevUser.skills,
          response.data.user.skills[response.data.user.skills.length - 1],
        ],
      }));
      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(response.data.user)
      );
      setNewSkill("");
    } catch (error) {
      console.error("Erreur lors de l'ajout de la compétence :", error);
    }
  };

  const handleRemoveSkill = async (userId, skillToRemove) => {
    try {
      const response = await axios.put(`${removeUserSkillRoute}/${userId}`, {
        skillToRemove,
      });

      setUser((prevUser) => ({
        ...prevUser,
        skills: response.data.user.skills,
      }));
      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(response.data.user)
      );
    } catch (error) {
      console.error("Erreur lors de la suppression de la compétence :", error);
    }
  };

  const handleUpdateSkills = () => {
    setShowModal(false);
  };

  const handleUpdateLocalisation = async (userId) => {
    try {
      const response = await axios.put(`${editProfileRoute}/${userId}`, {
        localisation: {
          city,
          country,
        },
      });
      setUser(response.data.user);
      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(response.data.user)
      );
      toast.success("Localisation mise à jour avec succès !");
      setShowModal(false);
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la localisation.");
    }
  };

  const handleUpdateDescription = async (userId) => {
    try {
      const response = await axios.put(`${editProfileRoute}/${userId}`, {
        description,
      });
      setUser(response.data.user);
      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(response.data.user)
      );
      toast.success("Description mise à jour avec succès !");
      setShowModal(false);
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la description.");
    }
  };

  const handleOpenModal = (type) => {
    setModalType(type);
    if (type === "localisation") {
      setCity(user.localisation.city);
      setCountry(user.localisation.country);
    } else if (type === "description") {
      setDescription(user.description);
    } else if (type === "skill") {
      setNewSkill(user.skill);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (!user) {
    return null;
  }

  console.log(user);
  return (
    <div>
      <div className="account-top">
        <div>
          <User
            avatar={user.avatarImage}
            showName={false}
            editable={false}
            type="large"
          />
        </div>
        <div className="account-user-information">
          <span className="account-user-name">
            {user.surname} {user.name}
          </span>
          <div className="container-editable">
            <p>
              <span>Localisation : </span>
              {user.localisation.city && (
                <span>{user.localisation.city}, </span>
              )}
              <span>{user.localisation.country}</span>
            </p>
            <Icon.Secondary>
              <FiEdit2 onClick={() => handleOpenModal("localisation")} />
            </Icon.Secondary>
          </div>
          <p className="main-activity"></p>
          <Button.Primary onClick={handleEditProfileClick}>
            Modifier mon profil.
          </Button.Primary>
        </div>
      </div>
      <div className="account-bottom">
        <div className="container-user-offer">
          <div className="user-offer">
            {userOffers.length === 0 ? (
              <div className="no-offer">
                <p>Vous n'avez pas d'offres.</p>
                <p>
                  <Button.Secondary onClick={handleCreateOfferClick}>
                    Créer une première offre !
                  </Button.Secondary>
                </p>
              </div>
            ) : (
              userOffers.map((offer) => (
                <Card
                  key={offer._id}
                  avatar={offer.user.avatarImage}
                  mentor={`${offer.user.name} ${offer.user.surname}`}
                  pricing={offer.pricing}
                  activity={offer.activity}
                  profession={offer.profession}
                  rating={offer.user.averageRating}
                  numberOfRating={offer.user.numberOfRatings}
                  offerModel={offer.offerModel}
                  duration={offer.duration}
                  premium={offer.premium}
                />
              ))
            )}
          </div>
        </div>
        <div className="container-more">
          <div className="section-more">
            <p className="more-text">Description :</p>
            <div className="container-editable">
              {user.description && user.description.length > 0 ? (
                user.skills.map((description, index) => (
                  <span key={index}>{description}</span>
                ))
              ) : (
                <span>Vous n'avez pas encore de description.</span>
              )}
              <Icon.Secondary>
                <FiEdit2 onClick={() => handleOpenModal("description")} />
              </Icon.Secondary>
            </div>
          </div>
          <div className="section-more">
            <p className="more-text">Compétences :</p>
            <div className="container-editable">
              <div className="skills-list">
                {user.skills && user.skills.length > 0 ? (
                  user.skills.map((skill, index) => (
                    <span key={index} className="user-skill">
                      {skill}
                    </span>
                  ))
                ) : (
                  <span>Aucune compétence affichée</span>
                )}
              </div>
              <Icon.Secondary>
                <FiEdit2 onClick={() => handleOpenModal("skill")} />
              </Icon.Secondary>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal
          label={
            modalType === "localisation"
              ? "Modifier la localisation"
              : modalType === "skill"
              ? "Modifier les compétences"
              : "Modifier la description"
          }
          onClose={handleCloseModal}
          onSave={() =>
            modalType === "localisation"
              ? handleUpdateLocalisation(user._id)
              : modalType === "skill"
              ? handleUpdateSkills(user._id)
              : handleUpdateDescription(user._id)
          }
        >
          <div>
            {modalType === "localisation" ? (
              <div className="user-offer">
                <Input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Ville"
                />
                <Input
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Pays"
                />
              </div>
            ) : modalType === "skill" ? (
              <div>
                <div className="skills-list column">
                  {user.skills && user.skills.length > 0 ? (
                    user.skills.map((skill, index) => (
                      <div className="list-with-cross">
                        <span key={index} className="user-skill">
                          {skill}
                        </span>
                        {hoveredSkillIndex === index ? (
                          <IoIosRemoveCircle
                            className="cross"
                            onMouseLeave={() => setHoveredSkillIndex(null)}
                            onClick={() => handleRemoveSkill(user._id, skill)}
                          />
                        ) : (
                          <IoIosRemoveCircleOutline
                            className="cross"
                            onMouseEnter={() => setHoveredSkillIndex(index)}
                            onClick={() => handleRemoveSkill(user._id, skill)}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <span>Aucune compétence affichée</span>
                  )}
                </div>
                <div className="skills-section">
                  <Input
                    type="text"
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value)}
                    placeholder="Ajouter une compétence"
                  />

                  <Button.Secondary onClick={() => handleAddSkill(user._id)}>
                    Ajouter le skill
                  </Button.Secondary>
                </div>
              </div>
            ) : (
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                className="modal-textarea"
              />
            )}
          </div>
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
}
