import React, { useState, useContext } from "react";
import imageCompression from "browser-image-compression";
import avatarDefault from "../../assets/avatar.png";
import { UserContext } from "../../context/userContext";
import { setAvatarRoute } from "../../utils/APIRoutes";
import Modal from "../Modal";
import Input from "../Input";
import Button from "../Button";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "./index.scss";

export default function User({
  showName = true,
  type = "normal",
  colorText = "light",
  editable = false,
  avatar,
  surname,
  name,
}) {
  const { user, setUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getTypeClass = () => {
    switch (type) {
      case "small":
        return "small";
      case "large":
        return "large";
      default:
        return "normal";
    }
  };

  const getColorText = () => {
    switch (colorText) {
      case "dark":
        return "dark";
      default:
        return "light";
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const base64Image = await convertToBase64(compressedFile);
        setSelectedImage(base64Image);
        setPreviewImage(URL.createObjectURL(compressedFile));
      } catch (error) {
        toast.error("Erreur lors de la compression de l'image.", toastOptions);
      }
    }
  };

  const handleSave = async () => {
    if (!selectedImage) {
      toast.error("Veuillez sélectionner une image.", toastOptions);
      return;
    }

    try {
      const { data } = await axios.post(`${setAvatarRoute}/${user._id}`, {
        image: selectedImage,
      });

      if (data.isSet) {
        const updatedUser = { ...user, avatarImage: data.image };
        setUser(updatedUser);
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(updatedUser)
        );
        toast.success("Avatar mis à jour avec succès !", toastOptions);
        setShowModal(false);
      } else {
        toast.error("Erreur lors de la mise à jour de l'avatar.", toastOptions);
      }
    } catch (error) {
      toast.error(
        "Une erreur est survenue. Réessayez plus tard.",
        toastOptions
      );
    }
  };

  return (
    <div
      className={`user-container ${editable ? "editable" : ""}`}
      onClick={editable ? () => setShowModal(true) : undefined}
    >
      <img
        src={avatar || user?.avatarImage || avatarDefault}
        alt="Avatar"
        className={getTypeClass()}
      />

      {showName && (
        <span className={getColorText()}>
          {surname} {name}
        </span>
      )}

      {editable && showModal && (
        <Modal onClose={() => setShowModal(false)} showSave={false}>
          <div className="modal-content">
            <div className="modal-body">
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <div className="avatar-container">
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="avatar-image"
                  />
                ) : (
                  <img
                    src={avatar || user?.avatarImage || avatarDefault}
                    alt="Avatar"
                    className={getTypeClass()}
                  />
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Button.Secondary onClick={() => setShowModal(false)}>
                Annuler
              </Button.Secondary>
              <Button.Primary className="btn-primary" onClick={handleSave}>
                Sauvegarder
              </Button.Primary>
            </div>
          </div>
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
}
