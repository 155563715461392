import React, { useContext } from "react";
import axios from "axios";
import { UserContext } from "../../../context/userContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BiSearch, BiUser } from "react-icons/bi";

import { setRoleRoute } from "../../../utils/APIRoutes";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

export default function SetRole({ onNext }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const handleRoleSelection = async (role) => {
    if (!user || !user._id) {
      toast.error("User not found", toastOptions);
      return;
    }

    try {
      const { data } = await axios.post(`${setRoleRoute}/${user._id}`, {
        role,
      });

      if (data.role) {
        user.role = data.role;
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(user)
        );
        toast.success(`Role set to ${role}`, toastOptions);
        onNext();
        navigate("/");
      } else {
        toast.error("Failed to set role. Please try again.", toastOptions);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", toastOptions);
      console.error("Error setting role:", error);
    }
  };

  return (
    <div>
      <div className="auth-role-title">
        <h1>Choisissez votre rôle</h1>
      </div>
      <div className="roles">
        <button
          className="role-button"
          onClick={() => handleRoleSelection("user")}
        >
          <span className="container-auth-role-choice">
            <BiSearch />
            <span>Utilisateur</span>
          </span>
        </button>
        <button
          className="role-button"
          onClick={() => handleRoleSelection("mentor")}
        >
          <div className="container-auth-role-choice">
            <BiUser />
            <span>Mentor</span>
          </div>
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}
