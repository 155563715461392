import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

import { UserContext } from "../../context/userContext";
import { loginRoute } from "../../utils/APIRoutes";

import Button from "../../components/Button";
import Input from "../../components/Input";
import "./index.scss";

export default function Login() {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/");
    }
  }, [navigate]);

  const validationSchema = Yup.object({
    email: Yup.string().email("E-mail invalide").required("E-mail requis"),
    password: Yup.string().required("Mot de passe requis"),
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const formErrors = {};
      validationErrors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    try {
      const { data } = await axios.post(loginRoute, values);
      if (data.status) {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem(
          process.env.REACT_APP_LOCALHOST_KEY,
          JSON.stringify(data.user)
        );

        setUser(data.user);
        navigate("/");
      } else {
        toast.error(data.msg, toastOptions);
      }
    } catch (error) {
      toast.error("E-mail ou mot de passe incorrect.", toastOptions);
    }
  };

  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <Input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Saisissez votre adresse e-mail"
            label="Adresse e-mail"
            error={errors.email}
          />
          <Input
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            placeholder="Entrez votre mot de passe"
            label="Mot de passe"
            error={errors.password}
          />
          <Button.Primary type="submit" label="S'identifier" />
        </form>
      </div>

      <ToastContainer />
    </>
  );
}
