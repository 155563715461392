import React from "react";
import "./index.scss";
import Button from "../Button";
import Icon from "../Icon";
import { RxCross1 } from "react-icons/rx";

const Modal = ({
  label,
  children,
  showSave = true,
  labelButton = "Enregistrer",
  onClose,
  onSave,
}) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={handleModalClick}>
        <div className="modal-header">
          <h4>{label}</h4>
          <Icon.Secondary onClick={onClose}>
            <RxCross1 />
          </Icon.Secondary>
        </div>
        <div className="modal-content">{children}</div>
        {showSave && (
          <div className="modal-save">
            <Button.Primary onClick={onSave} label={labelButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
