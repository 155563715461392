import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  getSubscriptionPlansRoute,
  createCheckoutSessionRoute,
  cancelSubscriptionRoute,
} from "../../utils/APIRoutes";
import "./index.scss";

export default function Subscription() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(getSubscriptionPlansRoute);
        const activePlans = response.data.filter((plan) => plan.product.active);
        console.log(activePlans);
        setPlans(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des plans d'abonnement :",
          error
        );
      }
    };

    fetchPlans();
  }, []);

  const handleSubscribe = async (priceId) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Token d'accès manquant !");
        return;
      }

      const { data } = await axios.post(
        createCheckoutSessionRoute,
        { priceId },
        {
          headers: {
            accessToken: token,
          },
        }
      );
      window.location.href = data.url;
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session de paiement :",
        error
      );
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Token d'accès manquant !");
        return;
      }

      await axios.post(
        cancelSubscriptionRoute,
        {},
        { headers: { accessToken: token } }
      );

      alert("Abonnement annulé avec succès");
    } catch (error) {
      console.error("Erreur lors de l'annulation :", error);
    }
  };

  return (
    <div className="subscription-container">
      <h2>Choisissez votre abonnement</h2>
      <div className="plans">
        {plans.map((plan) => (
          <div key={plan.id} className="plan">
            <h3>{plan.product.name}</h3>
            <p>{plan.product.description || "Pas de description disponible"}</p>
            <p>
              Prix : {(plan.unit_amount / 100).toFixed(2)}{" "}
              {plan.currency.toUpperCase()}
            </p>
            <button onClick={() => handleSubscribe(plan.id)}>S'abonner</button>
            <button onClick={handleCancelSubscription}>
              Annuler l'abonnement
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
