import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createOfferRoute } from "../../utils/APIRoutes";
import Card from "../../components/Card";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import "./index.scss";

const CreateOffer = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [pricing, setPricing] = useState("");
  const [activity, setActivity] = useState("");
  const [profession, setProfession] = useState("");
  const [offerModel, setOfferModel] = useState([]);
  const [duration, setDuration] = useState(30);

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      if (!storedUser) {
        navigate("/login");
      } else {
        setUser(storedUser);
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleOfferModelChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setOfferModel([...offerModel, value]);
    } else {
      setOfferModel(offerModel.filter((model) => model !== value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        createOfferRoute,
        {
          pricing,
          activity,
          profession,
          offerModel,
          duration,
        },
        {
          headers: {
            accessToken: token,
          },
        }
      );
      if (response.data.msg) {
        alert("Offre créée avec succès");
      } else {
        alert("Erreur lors de la création de l'offre");
      }
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire", error);
      alert("Erreur lors de la création de l'offre");
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container-create-offer">
      <div className="create-offer">
        <h2>Créer une nouvelle offre</h2>
        <form onSubmit={handleSubmit} className="create-offer-form">
          <Input
            label="Métier :"
            type="text"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            placeholder="Votre métier"
            required
          />

          <Input
            label="Activité :"
            type="text"
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
            placeholder="Votre activité"
            required
          />

          <Input
            label="Tarification (en euros) :"
            type="number"
            value={pricing}
            onChange={(e) => setPricing(e.target.value)}
            placeholder="Prix"
            required
          />

          <Select
            label="Durée de l'échange :"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
          >
            <option value={15}>15 minutes</option>
            <option value={30}>30 minutes</option>
            <option value={60}>1 heure</option>
          </Select>

          <div className="type-offer">
            <Input
              label="Message"
              type="checkbox"
              value="Message"
              onChange={handleOfferModelChange}
            />

            <Input
              label="Appel"
              type="checkbox"
              value="Appel"
              onChange={handleOfferModelChange}
            />

            <Input
              label="Rencontre"
              type="checkbox"
              value="Rencontre"
              onChange={handleOfferModelChange}
            />
          </div>

          <Button.Secondary type="submit">Créer l'offre</Button.Secondary>
        </form>
      </div>
      <div className="preview-offer">
        <Card
          avatar={user.avatarImage}
          mentor={`${user.surname} ${user.name}`}
          pricing={pricing}
          activity={activity}
          profession={profession}
          rating={user.averageRating}
          numberOfRating={user.numberOfRatings}
          offerModel={offerModel}
          duration={duration}
          premium={user.premium}
        />
      </div>
    </div>
  );
};

export default CreateOffer;
