import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerRoute } from "../../../utils/APIRoutes";
import { UserContext } from "../../../context/userContext";
import "./index.css";
import Button from "../../Button";
import Input from "../../Input";

export default function Register({ onNext }) {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    surname: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { password, confirmPassword, name, surname, email } = values;
    if (password !== confirmPassword) {
      toast.error(
        "Password and confirm password should be the same.",
        toastOptions
      );
      return false;
    } else if (surname.length < 3) {
      toast.error("Surname should be greater than 3 characters.", toastOptions);
      return false;
    } else if (name.length < 3) {
      toast.error("Name should be greater than 3 characters.", toastOptions);
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Password should be equal or greater than 8 characters.",
        toastOptions
      );
      return false;
    } else if (email === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      const { email, surname, name, password } = values;
      try {
        const { data } = await axios.post(registerRoute, {
          surname,
          name,
          email,
          password,
        });

        if (data.status === false) {
          toast.error(data.msg, toastOptions);
        } else if (data.status === true) {
          localStorage.setItem("accessToken", data.token);
          localStorage.setItem(
            process.env.REACT_APP_LOCALHOST_KEY,
            JSON.stringify(data.user)
          );

          setUser(data.user);
          onNext(data.user);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.", toastOptions);
      }
    }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit} className="register-form">
          <div className="register-name">
            <Input
              type="text"
              placeholder="Saisissez votre prénom"
              name="surname"
              onChange={handleChange}
              label="Prénom"
            />
            <Input
              type="text"
              placeholder="Saisissez votre nom"
              name="name"
              onChange={handleChange}
              label="Nom"
            />
          </div>
          <Input
            type="email"
            placeholder="Saisissez votre adresse e-mail"
            name="email"
            onChange={handleChange}
            label="Email"
          />
          <Input
            type="password"
            placeholder="Entrez votre mot de passe"
            name="password"
            onChange={handleChange}
            label="Mot de passe"
          />
          <Input
            type="password"
            placeholder="Confirmez votre mot de passe"
            name="confirmPassword"
            onChange={handleChange}
            label="Confirmez le mot de passe"
          />
          <Button.Primary type="submit" label="S'inscrire" />
        </form>
      </div>

      <ToastContainer />
    </>
  );
}
