import React from "react";
import { useNavigate } from "react-router-dom";
import { BiLogOutCircle, BiMessage, BiUser } from "react-icons/bi";
import { RiSettings4Line } from "react-icons/ri";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { RiHome5Line } from "react-icons/ri";
import axios from "axios";
import { logoutRoute } from "../../utils/APIRoutes";
import Button from "../Button";
import "./index.scss";

export default function Sidebar() {
  const navigate = useNavigate();

  const handleAccueilClick = () => {
    navigate("/");
  };

  const handleMessagesClick = () => {
    navigate("/messages");
  };

  const handleAccountClick = () => {
    navigate("/account");
  };

  const handlePremiumClick = () => {
    navigate("/subscription");
  };

  const handleParamClick = () => {
    navigate("/editProfile");
  };

  const handleClick = async () => {
    const id = await JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    )._id;
    const data = await axios.get(`${logoutRoute}/${id}`);
    if (data.status === 200) {
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-section">
        <Button.Tertiary onClick={handleAccueilClick}>
          <RiHome5Line />
          Accueil
        </Button.Tertiary>
        <Button.Tertiary onClick={handleMessagesClick}>
          <BiMessage />
          Messages
        </Button.Tertiary>
        <Button.Tertiary onClick={handleAccountClick}>
          <BiUser />
          Profil
        </Button.Tertiary>
        {/* <Button.Tertiary onClick={handleRevenuesClick}>
          <TbMoneybag />
          Revenues
        </Button.Tertiary>
        */}
      </div>
      <div className="sidebar-section">
        <Button.Tertiary onClick={handlePremiumClick}>
          <AiOutlinePlus />
          Premium
        </Button.Tertiary>
        <Button.Tertiary onClick={handleParamClick}>
          <RiSettings4Line />
          Paramètres
        </Button.Tertiary>
        <Button.Tertiary onClick={handleClick}>
          <BiLogOutCircle />
          Déconnexion
        </Button.Tertiary>
      </div>
    </div>
  );
}
