import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import "./index.scss";
import logo from "../../assets/zensor-white.png";

import User from "../../components/User";
import Search from "../../components/Search";
import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";

export default function HomeLayout() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const storedUser = localStorage.getItem(
      process.env.REACT_APP_LOCALHOST_KEY
    );
    if (!storedUser) {
      navigate("/login");
    } else {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, [navigate]);

  const handleExploreClick = () => {
    navigate("/");
  };

  const handleProfileClick = () => {
    navigate("/account");
  };

  const handleCreateClick = () => {
    navigate("/create");
  };

  return (
    <div className="home-container">
      <div className="navbar">
        <div className="navbar-container-navigation">
          <img src={logo} alt="Logo" className="logo" />
          <div className="vertical-bar" />
          <Search />
        </div>

        <div className="navbar-container-navigation">
          <Button.Primary onClick={handleExploreClick}>Explorer</Button.Primary>
          <Button.Primary onClick={handleCreateClick}>
            Créer une offre
          </Button.Primary>

          <div className="vertical-bar" />
          {user && currentUser && (
            <User
              type="small"
              user={currentUser}
              onClick={handleProfileClick}
              avatar={user.avatarImage}
              surname={user.surname}
              name={user.name}
            />
          )}
        </div>
      </div>

      <div className="main-container">
        <Sidebar />
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
