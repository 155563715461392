export const host = "https://zensor.app";
export const loginRoute = `${host}/api/auth/login`;
export const registerRoute = `${host}/api/auth/register`;
export const logoutRoute = `${host}/api/auth/logout`;

export const setAvatarRoute = `${host}/api/auth/setavatar`;
export const setRoleRoute = `${host}/api/auth/setrole`;

export const editProfileRoute = `${host}/api/auth/editprofile`;

export const allUsersRoute = `${host}/api/auth/allusers`;
export const userByIdRoute = `${host}/api/auth/user`;

export const getConversationRoute = `${host}/api/messages/conversations`;
export const sendMessageRoute = `${host}/api/messages/send`;
export const getMessageRoute = `${host}/api/messages/get`;
export const getOrCreateConversationRoute = `${host}/api/messages/conversation`;

export const getOffers = `${host}/api/offers`;
export const createOfferRoute = `${host}/api/offers/create`;
export const getUserOffersRoute = `${host}/api/offers/useroffer`;
export const allCategoriesRoute = `${host}/api/offers/categories`;

export const getCategoriesRoute = `${host}/api/categories`;
export const addCategoryRoute = `${host}/api/categories/add`;
export const removeCategoryRoute = `${host}/api/categories/remove`;

export const updateUserSkillsRoute = `${host}/api/auth/skills`;
export const removeUserSkillRoute = `${host}/api/auth/removeskill`;

export const getSubscriptionPlansRoute = `${host}/api/subscription/plans`;
export const createCheckoutSessionRoute = `${host}/api/subscription/create-checkout-session`;
export const cancelSubscriptionRoute = `${host}/api/subscription`;
