import React from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>Merci pour votre abonnement !</h1>
      <p>Votre transaction a été complétée avec succès.</p>
      <button onClick={() => navigate("/subscription")}>Voir mon profil</button>
    </div>
  );
};

export default Success;
