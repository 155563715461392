import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../context/userContext";
import { sendMessageRoute, getMessageRoute } from "../../utils/APIRoutes";
import ChatInput from "../ChatInput";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import "./index.scss";

export default function ChatContainer({ currentChat, socket }) {
  const { user: currentUser } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const scrollRef = useRef();

  const formatDate = (date) => {
    return format(new Date(date), "dd MMMM yyyy", { locale: fr });
  };

  const formatTime = (date) => {
    return format(new Date(date), "HH:mm", { locale: fr });
  };

  const groupMessagesByTimeAndDay = (messages) => {
    const grouped = [];
    let currentGroup = [];
    let currentDay = "";

    messages.forEach((message, index) => {
      const prevMessage = messages[index - 1];
      const messageDate = formatDate(message.timestamp);
      const currentTimestamp = new Date(message.timestamp).getTime();
      const prevTimestamp = prevMessage
        ? new Date(prevMessage.timestamp).getTime()
        : null;

      if (
        messageDate !== currentDay ||
        (prevTimestamp && currentTimestamp - prevTimestamp > 3 * 60 * 1000)
      ) {
        if (currentGroup.length > 0) {
          grouped.push({ day: currentDay, messages: currentGroup });
        }
        currentGroup = [];
        currentDay = messageDate;
      }

      currentGroup.push(message);

      if (index === messages.length - 1) {
        grouped.push({ day: currentDay, messages: currentGroup });
      }
    });

    return grouped;
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (!currentChat || !currentChat.conversationId) {
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(getMessageRoute, {
          params: {
            conversationId: currentChat.conversationId,
            currentUserId: currentUser._id,
          },
        });
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setIsLoading(false);
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (currentChat?.conversationId) {
      fetchMessages();
    }
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    socket.current.emit("send-msg", {
      to: currentChat.user._id,
      from: currentUser._id,
      msg,
    });
    await axios.post(sendMessageRoute, {
      from: currentUser._id,
      to: currentChat.user._id,
      message: msg,
    });

    const msgs = [
      ...messages,
      { fromSelf: true, message: msg, timestamp: new Date() },
    ];
    setMessages(msgs);
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (socket.current) {
      const handleMessageReceive = (msg) => {
        setArrivalMessage({
          fromSelf: false,
          message: msg,
          timestamp: new Date(),
        });
      };
      socket.current.on("msg-recieve", handleMessageReceive);

      return () => {
        socket.current.off("msg-recieve", handleMessageReceive);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (arrivalMessage) {
      setMessages((prevMessages) => [...prevMessages, arrivalMessage]);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const groupedMessages = groupMessagesByTimeAndDay(messages);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="container-chat">
      <div className="chat-messages">
        {groupedMessages.length === 0 && (
          <div className="no-messages">
            <p>
              Aucun message pour cette conversation. Soyez le premier à écrire !
            </p>
          </div>
        )}
        {groupedMessages.map((group, index) => (
          <div key={uuidv4()} className="message-group">
            {index === 0 || groupedMessages[index - 1].day !== group.day ? (
              <div className="message-date">
                <hr />
                <span>{group.day}</span>
                <hr />
              </div>
            ) : null}

            {group.messages.map((message, msgIndex) => (
              <div key={uuidv4()} className="message-container">
                <div
                  className={`message-content ${
                    message.fromSelf ? "sended" : "recieved"
                  }`}
                >
                  {(msgIndex === 0 ||
                    group.messages[msgIndex - 1].fromSelf !==
                      message.fromSelf) && (
                    <div className="information-user-message">
                      <img
                        src={
                          message.fromSelf
                            ? currentUser.avatarImage
                            : currentChat.user.avatarImage
                        }
                        alt="avatar"
                        className="message-avatar"
                      />
                      <span className="message-user-name">
                        {message.fromSelf
                          ? `${currentUser.name} ${currentUser.surname}`
                          : `${currentChat.user.name} ${currentChat.user.surname}`}
                      </span>
                      <span className="message-timestamp">-</span>
                      <span className="message-timestamp">
                        {formatTime(message.timestamp)}
                      </span>
                    </div>
                  )}

                  <p className="message">{message.message}</p>
                </div>
              </div>
            ))}
          </div>
        ))}

        <div ref={scrollRef} />
      </div>

      <ChatInput handleSendMsg={handleSendMsg} />
    </div>
  );
}
