import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getConversationRoute } from "../../utils/APIRoutes";
import ChatContainer from "../../components/ChatContainer";
import User from "../../components/User";
import useSocket from "../../hooks/useSocket";
import "./index.scss";

export default function Messages() {
  const navigate = useNavigate();
  const location = useLocation();
  const { conversationId } = location.state || {};
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const socket = useSocket(process.env.REACT_APP_HOST, currentUser?._id);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      if (!storedUser) {
        navigate("/login");
      } else {
        setCurrentUser(storedUser);
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      const fetchConversations = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `${getConversationRoute}/${currentUser._id}`
          );
          const filteredConversations = response.data.map((conversation) => {
            const otherUser = conversation.members.find(
              (member) => member._id !== currentUser._id
            );
            return {
              conversationId: conversation._id,
              user: otherUser,
              updatedAt: conversation.updatedAt,
            };
          });
          filteredConversations.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          setConversations(filteredConversations);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des conversations",
            error
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchConversations();
    }
  }, [currentUser]);

  useEffect(() => {
    if (conversationId && conversations.length > 0) {
      const selectedConversation = conversations.find(
        (conversation) => conversation.conversationId === conversationId
      );
      if (selectedConversation) {
        setCurrentChat(selectedConversation);
      } else {
        console.error("Conversation non trouvée pour cet ID :", conversationId);
      }
    }
  }, [conversationId, conversations]);

  const handleChatChange = (selectedConversation) => {
    setCurrentChat(selectedConversation);
  };

  return (
    <div className="container-messagerie">
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="users-list">
            {conversations.length === 0 ? (
              <p>Aucune conversation</p>
            ) : (
              conversations.map((conversation) => (
                <div
                  key={conversation.conversationId}
                  onClick={() => handleChatChange(conversation)}
                  className="users-chat"
                >
                  {conversation.user ? (
                    <User
                      type="small"
                      avatar={conversation.user.avatarImage}
                      surname={conversation.user.surname}
                      name={conversation.user.name}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))
            )}
          </div>

          <div className="chat-container">
            {currentChat === undefined ? (
              <></>
            ) : (
              <ChatContainer currentChat={currentChat} socket={socket} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
