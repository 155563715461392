import React, { useEffect, useState } from "react";
import { getOffers, getOrCreateConversationRoute } from "../../utils/APIRoutes";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Card from "../../components/Card";
import Modal from "../../components/Modal";
import User from "../../components/User";
import StarRating from "../../components/StarRating";

import "./index.scss";
import Button from "../../components/Button";

export default function Explore() {
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [conversation, setConversation] = useState(null);
  const [showConversationModal, setShowConversationModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem(
      process.env.REACT_APP_LOCALHOST_KEY
    );
    if (!storedUser) {
      navigate("/login");
    } else {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, [navigate]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(getOffers);
        const validOffers = response.data.filter(
          (offer) => offer.user && offer.user.avatarImage
        );
        setOffers(validOffers);
      } catch (error) {
        console.error("Erreur lors de la récupération des offres :", error);
      }
    };

    fetchOffers();
  }, []);

  const openModal = (offer) => {
    setSelectedOffer(offer);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedOffer(null);
  };

  const openChat = async () => {
    try {
      if (!selectedOffer || !selectedOffer.user) {
        console.error("Aucune offre ou utilisateur sélectionné.");
        return;
      }

      const userId = currentUser._id;
      const targetUserId = selectedOffer.user._id;

      const response = await axios.post(getOrCreateConversationRoute, {
        userId,
        targetUserId,
      });

      if (response.data && response.data._id) {
        const conversation = response.data;
        console.log("Conversation créée ou récupérée:", conversation);
        navigate("/messages", { state: { conversationId: conversation._id } });
      } else {
        console.error("Erreur : Conversation introuvable.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ouverture de la conversation :", error);
    }
  };

  const closeConversationModal = () => {
    setShowConversationModal(false);
    setConversation(null);
  };

  return (
    <div className="container-explore">
      {offers.map((offer) => (
        <Card
          key={offer._id}
          avatar={offer.user.avatarImage}
          mentor={`${offer.user.surname} ${offer.user.name}`}
          pricing={offer.pricing}
          activity={offer.activity}
          profession={offer.profession}
          rating={offer.user.averageRating}
          numberOfRating={offer.user.numberOfRatings}
          offerModel={offer.offerModel}
          duration={offer.duration}
          premium
          onClick={() => openModal(offer)}
        />
      ))}
      {showModal && selectedOffer && (
        <Modal onClose={closeModal} showSave={false}>
          <div>
            <div className="card-modal-content-top">
              <User
                avatar={selectedOffer.user.avatarImage}
                showName={false}
                editable={false}
                type="large"
              />
              <div>
                <p className="card-modal-name">
                  {selectedOffer.user.surname} {selectedOffer.user.name}
                </p>
                <p className="card-modal-profession">
                  {selectedOffer.profession}
                </p>
                <p>{selectedOffer.activity}</p>
                <p className="card-modal-rate">
                  <StarRating rating={selectedOffer.user.averageRating || ""} />
                </p>
              </div>
            </div>
            <div>
              <p className="card-modal-description">
                {selectedOffer.user.description ||
                  "Pas de description disponible."}
              </p>
              <p className="card-modal-tarif">
                Tarif de mentorat : {selectedOffer.pricing}€ /{" "}
                {selectedOffer.duration} minutes
              </p>
              <div className="modal-footer">
                <Button.Primary onClick={openChat} className="btn-open-chat">
                  Ouvrir la conversation
                </Button.Primary>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showConversationModal && conversation && (
        <Modal onClose={closeConversationModal} showSave={false}>
          <div className="conversation-modal-content">
            <h2>Conversation avec {conversation.targetUserName}</h2>
            <p>ID de la conversation : {conversation._id}</p>
          </div>
        </Modal>
      )}
    </div>
  );
}
