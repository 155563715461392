import { useRef, useEffect } from "react";
import { io } from "socket.io-client";

const useSocket = (url, userId) => {
  const socket = useRef();

  useEffect(() => {
    socket.current = io(url);
    if (userId) {
      socket.current.emit("add-user", userId);
    }

    return () => {
      socket.current.disconnect();
    };
  }, [url, userId]);

  return socket;
};

export default useSocket;
