import React from "react";
import "./index.scss";

const Icon = ({ children, variant = "primary", onClick }) => {
  return (
    <div className={`icon ${variant}`} onClick={onClick}>
      {" "}
      {children}
    </div>
  );
};

Icon.Primary = (props) => {
  return <Icon variant="icon-primary" {...props} />;
};

Icon.Secondary = (props) => {
  return <Icon variant="icon-secondary" {...props} />;
};

Icon.Tertiary = (props) => {
  return <Icon variant="icon-tertiary" {...props} />;
};

export default Icon;
