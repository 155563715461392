import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import Register from "./Register";
import SetAvatar from "./SetAvatar";
import SetRole from "./SetRole";
import "./index.scss";

export default function AuthWizard({ onStepChange }) {
  const [step, setStep] = useState(0);
  const { setUser } = useContext(UserContext);

  const totalSteps = 3;
  const progress = (step / totalSteps) * 100;

  const handleNext = (data) => {
    if (data) {
      setUser((prev) => ({ ...prev, ...data }));
    }
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    if (onStepChange) {
      onStepChange(step);
    }
  }, [step, onStepChange]);

  return (
    <div className="auth-wizard-container">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }} />
      </div>

      <div className="step-content">
        {step === 0 && <Register onNext={handleNext} />}
        {step === 1 && <SetAvatar onNext={handleNext} />}
        {step === 2 && <SetRole onNext={handleNext} />}
        {step > 2 && <h2>Inscription terminée !</h2>}
      </div>
    </div>
  );
}
