import React from "react";
import "./index.scss";

export default function Input({
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  label,
  error,
}) {
  const isCheckbox = type === "checkbox";
  return (
    <div className={`input-container ${isCheckbox ? "checkbox" : ""}`}>
      {label && <label className="input-label">{label}</label>}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`input-field ${error ? "input-error" : ""}`}
      />
      {error && <span className="input-error-message">{error}</span>}
    </div>
  );
}
