import React from "react";
import "react-toastify/dist/ReactToastify.css";
import avatarDefault from "../../assets/avatar.png";
import "./index.scss";

export default function Card({
  avatar,
  mentor,
  pricing,
  activity,
  profession,
  rating,
  numberOfRating,
  offerModel,
  duration,
  premium,
  onClick,
}) {
  return (
    <div className="card" onClick={onClick}>
      <div className="card-header">
        <img
          src={avatar || avatarDefault}
          alt="mentor"
          className="card-image"
        />
        {premium && <span className="badge">Premium</span>}
      </div>

      <div className="card-bottom">
        <div className="card-content">
          <div>
            <p className="card-title">{profession}</p>
            <p className="card-subtitle">{activity}</p>
          </div>
          <div className="card-rating">
            <svg className="star-icon" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 .587l3.668 7.435L23.5 9.673l-5.832 5.633L19.336 24 12 20.327 4.664 24l1.668-8.694L.5 9.673l7.832-1.65L12 .587z" />
            </svg>
            <span className="rating-value">{rating}</span>
            <span className="rating-count">({numberOfRating})</span>
          </div>
        </div>
        <div className="card-content">
          <div className="offer-model-container">
            {offerModel.map((model, index) => (
              <span className="offer-model" key={index}>
                {model}
              </span>
            ))}
          </div>
        </div>
        <div className="card-content">
          <p className="card-mentor">{mentor}</p>
          <p className="card-price">
            {pricing}€{" "}
            <span className="rating-count">/ {duration} minutes</span>
          </p>
        </div>
      </div>
    </div>
  );
}
