import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import User from "../../User";
import Button from "../../Button";
import "./index.scss";

export default function SetAvatar({ onNext }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  const skipAvatar = () => {
    onNext();
  };

  return (
    <div className="set-avatar">
      <p>Choisissez une image de profil qui vous représente !</p>
      <div className="avatar-container">
        <User avatar={user?.avatarImage} showName={false} editable={true} />
      </div>

      <div className="actions">
        <Button.Secondary onClick={skipAvatar}>
          Passer cette étape
        </Button.Secondary>
      </div>
    </div>
  );
}
