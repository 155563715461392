import React from "react";
import "./index.scss";

export default function Select({ value, onChange, children, label, error }) {
  return (
    <div className="select-container">
      {label && <label className="select-label">{label}</label>}
      <select
        value={value}
        onChange={onChange}
        className={`select-field ${error ? "select-error" : ""}`}
      >
        {children}
      </select>
      {error && <span className="select-error-message">{error}</span>}
    </div>
  );
}
