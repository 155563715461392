import React, { useState } from "react";
import { Link } from "react-router-dom";

import Login from "../../pages/Login";
import AuthWizard from "../../components/AuthWizard";
import logo from "../../assets/zensor-white.png";

import "./index.scss";

export default function AuthLayout() {
  const [isLogin, setIsLogin] = useState(true);
  const [isFirstStep, setIsFirstStep] = useState(true);

  const toggleAuth = () => {
    setIsLogin((prev) => !prev);
  };

  const handleStepChange = (step) => {
    setIsFirstStep(step === 0);
  };

  return (
    <div className="auth-container">
      <div className="auth-left-container">
        <div className="auth-content">
          <h2 className="zensor-text">Bienvenue sur Zensor !</h2>
          {isLogin ? (
            <>
              <Login />
              <span className="log-choice">
                Vous débutez sur Zensor ?{" "}
                <Link onClick={toggleAuth}>S’inscrire</Link>
              </span>
            </>
          ) : (
            <>
              <AuthWizard onStepChange={handleStepChange} />
              {isFirstStep && (
                <span className="log-choice">
                  Déjà inscrit(e) ?{" "}
                  <Link onClick={toggleAuth}>S’identifier</Link>
                </span>
              )}
            </>
          )}
        </div>
      </div>

      <div className="auth-right-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </div>
  );
}
