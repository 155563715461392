import React, { useState, useEffect } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import { allUsersRoute } from "../../utils/APIRoutes";
import "./index.scss";

const Search = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(allUsersRoute);
      setUsers(response.data);
    } catch (error) {
      setError("Erreur lors de la récupération des utilisateurs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const debouncedFilterUsers = debounce((term) => {
      if (term) {
        setFilteredUsers(
          users.filter((user) =>
            user.name.toLowerCase().includes(term.toLowerCase())
          )
        );
      } else {
        setFilteredUsers([]);
      }
    }, 300);

    debouncedFilterUsers(searchTerm);

    return () => {
      debouncedFilterUsers.cancel();
    };
  }, [searchTerm, users]);

  if (error) return <div>{error}</div>;

  return (
    <div className="search-container">
      {loading ? (
        <div className="loading-message">Chargement...</div>
      ) : (
        <>
          <input
            type="search"
            placeholder="Rechercher des utilisateurs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          {filteredUsers.length > 0 && (
            <ul className="search-results">
              {filteredUsers.map((user) => (
                <li key={user._id} className="search-result-item">
                  {user.name}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default Search;
