import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import HomeLayout from "./layouts/HomeLayout";
import Login from "./pages/Login";
import Register from "./components/AuthWizard/Register";
import SetAvatar from "./components/AuthWizard/SetAvatar";
import SetRole from "./components/AuthWizard/SetRole";

import Explore from "./pages/Explore";
import Profile from "./pages/Profile";
import CreateOffer from "./pages/CreateOffer";
import AuthLayout from "./layouts/AuthLayout";
import Account from "./pages/Account";
import Messages from "./pages/Messages";
import Subscription from "./pages/Subscription";
import Success from "./pages/Success";

export default function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/setavatar" element={<SetAvatar />} />
            <Route path="/setrole" element={<SetRole />} />
          </Route>

          <Route path="/" element={<HomeLayout />}>
            <Route index element={<Explore />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/messages/:conversationId" element={<Messages />} />
            <Route path="editProfile" element={<Profile />} />
            <Route path="account" element={<Account />} />
            <Route path="create" element={<CreateOffer />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="success" element={<Success />} />
          </Route>
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}
