import React, { useState } from "react";
import { IoMdSend } from "react-icons/io";
import Input from "../Input";
import Button from "../Button";
import "./index.scss";

export default function ChatInput({ handleSendMsg }) {
  const [msg, setMsg] = useState("");

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  return (
    <div className="container-chat-input">
      <form className="form-chat-input" onSubmit={sendChat}>
        <Input
          type="text"
          placeholder="Rédigez un message..."
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
        />
        <Button type="submit">
          <IoMdSend />
        </Button>
      </form>
    </div>
  );
}
